import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { BehaviorSubject, Observable, of, Subscription, throwError } from 'rxjs';
import { map, retry, share, switchMap, timeout } from 'rxjs/operators';

import { KsResponse } from '../../interfaces/ks-response.interface';
import { Api } from '../api/api';
import { LocalStorage } from '../local-storage/local-storage';
import { MetricsService } from '../metrics/metrics.service';

@Injectable()
export class CountryService {
  countrySub: Subscription;
  country = new BehaviorSubject<string | undefined>(undefined);
  clientCountry$ = new BehaviorSubject(undefined);

  allowedCountries = ['BR', 'MX', 'PE', 'CO', 'AR', 'EC', 'CL', 'ES', 'US', 'NL'];
  espPortCountries = ['BR', 'MX', 'PE', 'CO', 'AR', 'EC', 'CL', 'ES'];

  constructor(
    public api: Api,
    private http: HttpClient,
    private metrics: MetricsService,
    private localStorage: LocalStorage
  ) {}
  get currentCountry() {
    return this.country.value;
  }

  set clientCountry(value) {
    this.clientCountry$.next(value);
  }

  get clientCountry() {
    return this.clientCountry$.value;
  }

  get isUSGlobal(): boolean {
    return !this.espPortCountries.includes(this.country.value);
  }

  isAllowedCountry(country: string) {
    return this.allowedCountries.includes(country.toLocaleUpperCase());
  }

  isValidCountry(country: string) {
    return country && country.length <= 3;
  }

  setCountry(country: string) {
    // force KR to US
    if (country === 'KR') {
      country = 'US';
    }
    this.localStorage.setItem('country', country);
    this.country.next(country);
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    this.countrySub ? this.countrySub.unsubscribe() : null;
  }

  // ks server
  getCountryKS(ip?: string): Observable<any> {
    return this.api.get('country', ip ? { ip } : undefined).pipe(
      timeout(10000),
      switchMap((res: KsResponse<any>) => {
        if (res.success) {
          return of(res.data);
        } else {
          return throwError({
            name: 'DataSendedError',
            message: res['message'] || 'Ocurrio un error inesperado',
          });
        }
      }),
      retry(2),
      share()
    );
  }

  // brigde by neutrino and cloud functions
  getCountry(ip?: string): Observable<string> {
    if (this.currentCountry) {
      return of(this.currentCountry);
    }
    return this.http.get(environment.firebase.functionsURL + '/getCountry' + (ip ? `?ip=${ip}` : '')).pipe(
      timeout(5000),
      map(res => res as { country: string }),
      switchMap((res: any) => {
        if (res.country) {
          return of(res.country);
        } else {
          return this.getCountryKS(ip);
        }
      }),
      // retry(2),
      share()
    );
  }

  registerCountryDataOnMetrics() {
    this.metrics.register({ 'Kanto Country': this.currentCountry });
  }

  callCountryApi(ip?: string) {
    const countryObservable = this.getCountry(ip);

    this.countrySub = countryObservable.subscribe(this.setCountry.bind(this), error => {
      this.metrics.sendEvent('errorOnGetCountry', {
        name: error.name,
        message: error.message,
      });
    });
    return countryObservable;
  }

  getClientCountry() {
    this.getCountry().subscribe(this.clientCountry$);
  }

  onReady(callBack: (r?: any) => any): Subscription {
    const countrySub = this.country.subscribe(ready => {
      if (ready) {
        callBack();
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        countrySub ? countrySub.unsubscribe() : null;
      }
    });
    return countrySub;
  }
}
